body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
}
:root {
  --primary-color: #006BFF;
  --background-color: #ffffff;
  --hover-color: #f6f6f6;
  --stroke-color: #a8a8a8;
  --text-color: #000;
  --primary-text-color: #ffffff;
  --light-text-color: #666666;
  --light-background-color: #F9F9F9;
  --light-stroke-color: #E6E7E9;
  --canvas-color: #E6E7E9;
}